import dayjs from "dayjs";
import "dayjs/locale/ja";
import "dayjs/locale/zh";
import relativeTime from "dayjs/plugin/relativeTime";
import { fallbackLng } from "./i18n/settings";

dayjs.extend(relativeTime);
dayjs.locale(fallbackLng);

export default dayjs;
